export const _getStoreCredit = () => import('./get-store-credit');
export const _getStoreCreditByCpfCnpj = () => import('./get-store-credit-by-cpf-cnpj');
export const _getBalanceStoreCredit = () => import('./get-balance-store-credit');
export const _getBalanceStoreCreditByCpfCnpj = () => import('./get-balance-store-credit-by-cpf-cnpj');
export const _getValidateBalanceStoreCredit = () => import('./get-validate-balance-store-credit');
export const _getValidateBalanceStoreCreditByCpfCnpj = () => import('./get-validate-balance-store-credit-by-cpf-cnpj');
export const _grenGetStockStatus = () => import('./gren-get-stock-status');
export const _grenGetTrackCode = () => import('./get-track-code');
export const _grenGetInvoice = () => import('./gren-get-invoice');
export const _getProductReview = () => import('./get-product-review');
export const _grenGetEmailIsRegistered = () => import('./get-email-is-registered');
export const _listProductsCustom = () => import('./list-products-custom');
export const _getStockStatusCustom = () => import('./get-stock-status-custom');
export const _sendFacebookEvent = () => import('./send-facebook-event');
export const _listOrdersCustom = () => import('./list-orders-custom');
export const _listSkusCustom = () => import('./list-skus-custom');
export const _listAllSitesOrders = () => import('./list-all-sites-orders');
export const _getAllSitesOrder = () => import('./get-all-sites-order');
export const _getOrderCustom = () => import('./get-order-custom');
export const _getShopBySize = () => import('./get-shop-by-size');
export const _createProfile = () => import('./create-profile');
export const _updateProfile = () => import('./update-profile');
export const _getAiRecommendations = () => import('./get-ai-recommendations');
export const _getPromotionUpsellMessagesCustom = () => import('./get-promotion-upsell-messages-custom');
export const _getAddress = () => import('./get-address');
export const _getAddressFromZipcode = () => import('./get-address-from-zipcode');
export const _getIp = () => import('./get-ip');
export const _autosugestTypeahead = () => import('./typeahead');
export const _listInventories = () => import('./list-inventories');
export const _uploadDoc = () => import('./uploadDoc');
export const _getHubCombinations = () => import('./hub-get-combinations');
export const _getBaseHubCombinations = () => import('./get-base-hub-combinations');
export const _addShipping = () => import('./add-shipping');
export const _updateShipping = () => import('./update-shipping');
export const _getHubStoresRepository = () => import('./hub-stores-repository');
export const _loginKonviva = () => import('./login-konviva');
